import React, { useState, useEffect, useCallback } from 'react';
import { ImageBanner, ImageCarousel } from '@homeexchange/design';
import { connect } from 'react-redux';
import i18n from '../../i18n';
import PropTypes from 'prop-types';
import Home from '../../models/Home';
import User from '../../models/User';
import Utils from '../../utils/utils';
import {
    BUTTON_BLOCKER_EVENT,
    HOME_VIEW_EVENT,
    MODAL_BLOCKER_EVENT,
    trackHomeview
} from '../../utils/utils-ts';
import useGTM from '../analytics/useGTM';
import Analytics from '../../utils/analytics';
import { NAVIGATION_ACTION, NAVIGATION_AREAS, NAVIGATION_TEXT } from '../analytics/analytics-constants';
import { HomeBlocker } from './contact-ts/home-blocker';
import Api from '../../api/Api';

const HomeViewCarousel = ({ home, user, accessToken }) => {
    const [showModalPhotoBlock, setShowModalPhotoBlock] = useState(false);
    const [isPhotoBlockActive, setIsPhotoBlockActive] = useState(false);
    const [images, setImages] = useState([]);
    const [isAccessToken, setIsAccessToken] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    const getImages = useCallback(
        (isUserOffline) => {
            setImages(home ? home.pictures() : []);
            if (isUserOffline) {
                setIsAccessToken(true);
            }
        },
        [home, user]
    );

    useEffect(() => {
        if (!isAccessToken && !accessToken && home) {
            getImages(true);
        }
        if (home && user) {
            getImages(false);
            setIsPhotoBlockActive(Utils.isPhotoBlocked(user));
        }
    }, [accessToken, home, user, getImages, isAccessToken]);

    const handleImageChange = (index) => {
        if (!accessToken) {
            const { fetchNavigation } = useGTM(user);
            Analytics.trackGTM(
                'button',
                fetchNavigation(
                    NAVIGATION_ACTION.CLICK,
                    NAVIGATION_AREAS.CAROUSEL,
                    NAVIGATION_TEXT.CAROUSEL_SIGN_UP
                )
            );
            document.dispatchEvent(new CustomEvent('open_signup_popup'));
            return;
        }
        if (isPhotoBlockActive) {
            setShowModalPhotoBlock(true);
            trackHomeview(MODAL_BLOCKER_EVENT, 'photo_modal_blocker');
            return;
        }
        trackHomeview(HOME_VIEW_EVENT, 'caroussel_photo');
        setCurrentIndex(index ?? 0);
        setIsOpen(true);
    };

    const handleSliderImageChange = (index) => {
        if (index < 5) {
            return;
        }
        if (!accessToken) {
            document.getElementById('signup')?.click();
            return;
        }
        if (isPhotoBlockActive) {
            setShowModalPhotoBlock(true);
            return;
        }
    };

    const updatedImages = images.map((image) => {
        const url = new URL(image.src);
        url.searchParams.set('height', '1080');
        return { ...image, src: url.toString() };
    });

    const renderModalPhotoBlock = () => {
        if (!user) {
            return;
        }
        const userHomes = user.get('homes');

        if (!userHomes || userHomes.length === 0) {
            return (
                <HomeBlocker
                    title={`${i18n.t('home:picture-blocker.cant_see_pictures')}`}
                    message={`${i18n.t('home:picture-blocker.no_home_text')}`}
                    confirmAction={() => {
                        trackHomeview(BUTTON_BLOCKER_EVENT, 'photo_create_home', null);
                        window.location.href = i18n.t('home:home.create.url');
                    }}
                    confirmText={i18n.t('home:create_a_home')}
                    isOpen={showModalPhotoBlock}
                    setIsOpen={() => setShowModalPhotoBlock(false)}
                />
            );
        }

        const myHomes = Api.Home.sortByStatusAndCompletionRate(userHomes);
        const myHome = myHomes[0];

        if (myHome.get('completion_rate') < Home.MINIMUM_FILLING_TO_CONTACT) {
            return (
                <HomeBlocker
                    title={`${i18n.t('home:picture-blocker.cant_see_pictures')}`}
                    message={`${i18n.t('home:picture-blocker.home_filling_text')}`}
                    confirmAction={() => {
                        trackHomeview(BUTTON_BLOCKER_EVENT, 'photo_complete_home', null);
                        window.location.href = myHome.getEditUrl();
                    }}
                    confirmText={i18n.t('home:filling_my_home')}
                    isOpen={showModalPhotoBlock}
                    setIsOpen={() => setShowModalPhotoBlock(false)}
                />
            );
        }

        if (myHome.get('status') != Home.STATUS_ONLINE) {
            return (
                <HomeBlocker
                    title={`${i18n.t('home:picture-blocker.cant_see_pictures')}`}
                    message={`${i18n.t('home:picture-blocker.home_offline_text')}`}
                    confirmAction={() => {
                        trackHomeview(BUTTON_BLOCKER_EVENT, 'photo_home_offline', null);
                        window.location.href = myHome.getEditUrl();
                    }}
                    confirmText={i18n.t('home:publish_my_home')}
                    isOpen={showModalPhotoBlock}
                    setIsOpen={() => setShowModalPhotoBlock(false)}
                />
            );
        }

        return;
    };

    return (
        images.length > 0 && (
            <>
                <ImageBanner
                    images={images}
                    onChange={handleImageChange}
                    onSlideChange={handleSliderImageChange}
                    blur={isPhotoBlockActive || !accessToken}
                    onClickButton={images.length > 5 ? () => handleImageChange() : undefined}
                    buttonLabel={i18n.t('home:btn-home-gallery')}
                />
                <ImageCarousel
                    openSlide={currentIndex}
                    isOpen={isOpen}
                    images={updatedImages}
                    onClose={() => setIsOpen(false)}
                    closeLabel={i18n.t('home:close')}
                    className={'modal-carrousel'}
                />
                {renderModalPhotoBlock()}
            </>
        )
    );
};

HomeViewCarousel.propTypes = {
    home: PropTypes.instanceOf(Home),
    user: PropTypes.instanceOf(User),
    accessToken: PropTypes.bool.isRequired
};

const getHomeFromState = (state, ownProps) => {
    return state.loadedHomes?.[ownProps.homeId] ?? null;
};

const mapStateToProps = (state, ownProps) => ({
    home: getHomeFromState(state, ownProps),
    user: state.user,
    accessToken: Boolean(state.auth.accessToken)
});

export default connect(mapStateToProps)(HomeViewCarousel);
