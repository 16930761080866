import PropTypes from 'prop-types';
import React from 'react';

import Availability from '../../models/Availability';
import i18n from '../../i18n';

export default class ExchangeTypeSelect extends React.PureComponent {
    static propTypes = {
        exchangeType: PropTypes.number,
        onChange: PropTypes.func.isRequired,
        availabilities: PropTypes.arrayOf(PropTypes.instanceOf(Availability)),
        startOn: PropTypes.instanceOf(moment),
        endOn: PropTypes.instanceOf(moment)
    };

    constructor(props) {
        super(props);
        this.disableSelectionItem = this.disableSelectionItem.bind(this);
    }

    disableSelectionItem(optionValue) {
        const { startOn, endOn, availabilities, exchangeType } = this.props;
        if (!startOn || !endOn || !availabilities || _.isEmpty(availabilities)) {
            return false;
        }
        if (exchangeType !== Availability.AVAILABLE.value) {
            const exchangeDateRange = moment.range(startOn, endOn);
            // we have tu exclude availabilities that could match 'any type'
            const matchingAvailability = availabilities
                .filter((av) => av.get('type') !== Availability.AVAILABLE.type)
                .find((av) => av.range().contains(exchangeDateRange));

            return matchingAvailability && optionValue !== exchangeType;
        }
        return false;
    }

    render() {
        return (
            <select
                className="form-control"
                name="exchange_type"
                id="exchange_type"
                value={this.props.exchangeType || Availability.AVAILABLE.type}
                onChange={this.props.onChange}
            >
                {Availability.availabilitiesTypes
                    .filter((av) => av.id !== Availability.BOOKED.id && av.id !== Availability.RESERVED.id)
                    .map((av) => {
                        const disabled = this.disableSelectionItem(av.value);
                        return (
                            <option key={av.id} value={av.value} disabled={disabled}>
                                {i18n.t(av.selectPopupText)}
                            </option>
                        );
                    })}
            </select>
        );
    }
}
