import PropTypes from 'prop-types';
import React from 'react';

import i18n from '../../../i18n';
import Api from '../../../api/Api';

import Availability from '../../../models/Availability';
import HomeModel from '../../../models/Home';
import UserModel from '../../../models/User';
import Utils from '../../../utils/utils';

import MainView from './MainView';
import Verification from './Verification';
import Processing from './Processing';
import Success from './Success';
import ContactError from './ContactError';
import Loading from '../../common/Loading';
import useCollectionHomeStatusBlocker from '../../home-status-blocker/useCollectionHomeStatusBlocker';

export default class Contact extends React.PureComponent {
    static propTypes = {
        user: PropTypes.instanceOf(UserModel).isRequired,
        home: PropTypes.instanceOf(HomeModel).isRequired,
        updateHome: PropTypes.func.isRequired,
        userHomes: PropTypes.arrayOf(PropTypes.instanceOf(HomeModel)),
        availabilities: PropTypes.arrayOf(PropTypes.instanceOf(Availability))
    };

    static defaultProps = {
        userHomes: [],
        availabilities: []
    };

    static nbConversationsObjective = 10;
    static nbGPObjective = 50;

    constructor(props) {
        super(props);

        this.state = {
            step: 'form',
            myHome: this.getUserHome(props.userHomes),
            isPublishingHome: false,
            nbConversations: null,
            search: null
        };

        this.publishHome = this.publishHome.bind(this);
        this.onMessageSent = this.onMessageSent.bind(this);
        this.updateLastSearchFromContactPopup = this.updateLastSearchFromContactPopup.bind(this);
    }

    componentDidMount() {
        this.loadLastSearch();
    }

    UNSAFE_componentWillUpdate() {
        // reset content class used by content transition animation
        $('.popup-contact').removeClass('popup-contact-appear popup-contact-appear-active');
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            myHome: this.getUserHome(nextProps.userHomes)
        });
    }

    componentDidUpdate(prevProps, prevState) {
        // animate content transition
        if (prevState.step !== this.state.step) {
            const $popup = $('.popup-contact');
            $popup.addClass('popup-contact-appear');
            setTimeout(() => {
                $popup.addClass('popup-contact-appear-active');
            }, 0);
        }
    }

    getUserHome(homes) {
        // get user home to check his home filling and publication state
        const myHomes = Api.Home.sortByStatusAndCompletionRate(homes);
        return _.first(myHomes);
    }

    // preset last date range based on user's last search
    loadLastSearch() {
        Api.Search.getLastSearch().then((search) => {
            if (search) {
                this.setState({
                    search
                });
            } else {
                Api.Search.getLocalLastSearch(this.props.user.get('id')).then((result) => {
                    this.setState({
                        search: result
                    });
                });
            }
        });
    }

    updateLastSearchFromContactPopup(lastSearch) {
        this.setState({
            search: Utils.deepclone(lastSearch)
        });
    }

    publishHome(event) {
        event.preventDefault();
        const hasBlocker = useCollectionHomeStatusBlocker(this.props.user, this.props.home);

        if (hasBlocker === false) {
            this.setState({ isPublishingHome: true });
            const home = {
                id: this.state.myHome.id,
                status: HomeModel.STATUS_ONLINE
            };
            this.props.updateHome(home).then(() => {
                this.setState({ isPublishingHome: false });
            });
        }
    }

    onMessageSent() {
        this.setState({ step: 'success' });
    }

    render() {
        const { home, user } = this.props;
        // error view
        if (this.state.step === 'error') {
            return <ContactError />;
        }
        // loader
        if (this.state.step === 'loading') {
            return (
                <section className="popup-contact">
                    <div className="modal-body text-center vertical-center">
                        <Loading />
                    </div>
                </section>
            );
        }
        // home's owner wants only be contact by verified members
        if (home.get('user').get('verified_only') && !user.isVerified()) {
            return (
                <Verification
                    user={this.props.user}
                    title={i18n.t('exchange:contact.only_contact_by_verified', {
                        first_name: home.get('user').get('first_name')
                    })}
                />
            );
        }
        // processing contact form
        if (this.state.step === 'processing') {
            return <Processing home={home} />;
        }
        // success view
        if (this.state.step === 'success') {
            return (
                <Success
                    home={home}
                    nbConversations={this.state.nbConversations}
                    nbConversationsObjective={Contact.nbConversationsObjective}
                    nbGPObjective={Contact.nbGPObjective}
                    search={this.state.search}
                />
            );
        }
        // form view
        return (
            <MainView
                user={this.props.user}
                home={home}
                availabilities={this.props.availabilities}
                nbConversationsObjective={Contact.nbConversationsObjective}
                onMessageSent={this.onMessageSent}
                updateLastSearch={this.updateLastSearchFromContactPopup}
            />
        );
    }
}
