import React from 'react';

import i18n from '../../../i18n';

import ErrorIcon from '../animations/Error';

export default class ContactError extends React.PureComponent {
    render() {
        return (
            <section className="popup-contact">
                <header className="modal-header">
                    <h1 className="modal-title">{i18n.t('exchange:contact.cant_contact')}</h1>
                </header>
                <div className="modal-body text-center vertical-center">
                    <div className="popup-icon">
                        <ErrorIcon />
                    </div>
                    <h1
                        dangerouslySetInnerHTML={{
                            __html: i18n.t('common:an_error_occured')
                        }}
                    />
                </div>
                <footer className="modal-footer">
                    <button type="button" className="btn btn-primary pull-right" data-dismiss="modal">
                        {i18n.t('common:ok')}
                    </button>
                </footer>
            </section>
        );
    }
}
