import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18n from '../../i18n';
import Home from '../../models/Home';
import User from '../../models/User';
import Loading from '../../../src/components/common/Loading';
import CollectionLabel from './CollectionLabel';

const HomeHeader = (props) => {
    return !props.home || !props.user ? (
        <Loading />
    ) : (
        <>
            {props.home.get('shared_part') === Home.SHARED_PART_PRIVATE_ROOM && (
                <div>{i18n.t('home:home.view.private_room').toUpperCase()}</div>
            )}
            <CollectionLabel home={props.home} user={props.user} />
            <div className="home-header-row">
                <div className="home-header-row-title">
                    {props.home.isVerified() && <span className="badge-verified" />}
                    <div className="title">
                        <h1
                            id="title"
                            className={`${props.home.isVerified() ? 'verified' : 'not-verified'}`}
                            itemProp="name"
                        >
                            {props.home.title()}
                        </h1>
                    </div>
                </div>
                <div className="gp-value">
                    {props.home && props.home.get('guestpoint')}
                    <div>GP {i18n.t('home:night')}</div>
                </div>
            </div>
        </>
    );
};

HomeHeader.propTypes = {
    home: PropTypes.instanceOf(Home),
    user: PropTypes.instanceOf(User)
};

const mapStateToProps = (state, ownProps) => ({
    user: state.user,
    home: state.loadedHomes[ownProps.homeId]
});

export default connect(mapStateToProps)(HomeHeader);
