import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import HomeAllergiesInformation from '../../components/home-allergies/HomeAllergiesInformation';
import HomeViewAmenities from './HomeViewAmenities';
import HomeViewSection from './HomeViewSection';
import Feature from '../../models/Feature';
import Loading from '../common/Loading';
import Home from '../../models/Home';
import i18n from '../../i18n';

const HomeFeatures = ({ home }) => {
    const [isLoading, setLoading] = useState(true);
    const [features, setFeatures] = useState();

    useEffect(() => {
        if (home) {
            setFeatures(home.get('feature'));
            setLoading(false);
        }
    }, [home]);

    return (
        <>
            {!isLoading && (
                <HomeViewSection
                    classNameContainer="container-surrounding"
                    classNameChild="feature"
                    features={features}
                    featureType={Feature.SURROUNDING_TYPE_TAGS}
                    hasFeature={features?.surrounding > 0}
                    title="home:location"
                />
            )}
            <h2>{i18n.t('home:amenities')}</h2>
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <HomeViewAmenities features={features} />
                    <HomeAllergiesInformation features={features} />
                    <HomeViewSection
                        classNameContainer="row"
                        classNameChild="col-xs-6 col-sm-4 feature"
                        features={features}
                        featureType={Feature.AMENITIES_TYPE_RULE_FAVOR}
                        hasFeature={features?.rule + features?.favor > 0}
                    />
                </>
            )}
        </>
    );
};

HomeFeatures.propTypes = {
    home: PropTypes.instanceOf(Home)
};

const mapStateToProps = (state, ownProps) => ({
    home: state.loadedHomes[ownProps.homeId]
});

export default connect(mapStateToProps)(HomeFeatures);
