import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import i18n from '../../i18n';
import User from '../../models/User';

import { getBestDescription } from '../../utils/Intl';

import Loading from '../common/Loading';
import TranslateButton from './../common/TranslateButton';
import UserLanguagesList from './../common/UserLanguagesList';
import TranslateContent from './../common/TranslateContent';
import { getTextLegaleRegistrations } from '../../components/legal-registration/LegalRegistration';
import Tooltip from '../widget/Tooltip';

export class HomeDescription extends React.PureComponent {
    timeout = null;

    static propTypes = {
        homeId: PropTypes.number,
        descriptions: PropTypes.object,
        user: PropTypes.instanceOf(User),
        legalRegistration: PropTypes.array
    };

    static defaultProps = {
        descriptions: {}
    };

    constructor(props) {
        super(props);
        this.state = {
            descriptionLanguage: i18n.language,
            loading: false,
            copiedActive: false
        };
        this.setLocale = this.setLocale.bind(this);
        this.handleLoad = this.handleLoad.bind(this);
        this.handleHomeIdCopy = this.handleHomeIdCopy.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.user) {
            const bestDescription = getBestDescription(nextProps.descriptions, nextProps.user.get('locale'));
            this.setState({ descriptionLanguage: bestDescription.locale });
        }
    }

    componentWillUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    setLocale(descriptionLanguage) {
        this.setState({ descriptionLanguage });
    }

    handleLoad(loading) {
        this.setState({ loading });
    }

    handleHomeIdCopy() {
        this.setState({
            copiedActive: true
        });

        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        setTimeout(() => {
            this.setState({
                copiedActive: false
            });
        }, 6000);
    }

    render() {
        const { homeId, descriptions, user, legalRegistration } = this.props;
        const { descriptionLanguage, loading, copiedActive } = this.state;
        if (!user || !descriptionLanguage) {
            return (
                <div>
                    <h2>
                        {i18n.t('home:home_description')}
                        <Loading />
                    </h2>
                </div>
            );
        }
        const languages = descriptions ? Object.keys(descriptions) : [];

        return (
            <div>
                <h2>
                    {i18n.t('home:home_description')}
                    <TranslateButton
                        translations={descriptions}
                        user={user}
                        toLanguage={descriptionLanguage}
                        onChange={this.setLocale}
                        loading={loading}
                    />
                </h2>

                <UserLanguagesList
                    user={user}
                    languages={languages}
                    onChange={this.setLocale}
                    mode={'home'}
                />

                <h3>{i18n.t('home:good_feature_description')}</h3>
                <div id="good_feature" itemProp="description">
                    <TranslateContent
                        translations={descriptions}
                        content="good_feature"
                        toLanguage={descriptionLanguage}
                        onLoad={this.handleLoad}
                    />
                </div>

                <h3>{i18n.t('home:good_place_description')}</h3>
                <div id="good_place">
                    <TranslateContent
                        translations={descriptions}
                        content="good_place"
                        toLanguage={descriptionLanguage}
                        onLoad={this.handleLoad}
                    />
                </div>

                <h3>{i18n.t('home:other')}</h3>
                <div id="other">
                    <TranslateContent
                        translations={descriptions}
                        content="other"
                        toLanguage={descriptionLanguage}
                        onLoad={this.handleLoad}
                    />
                </div>

                {homeId && (
                    <React.Fragment>
                        <h3>{i18n.t('home:home_id')}</h3>
                        <div id="home_id">
                            <CopyToClipboard
                                text={
                                    document.location.hostname + i18n.t('home:home.view.url', { id: homeId })
                                }
                                onCopy={this.handleHomeIdCopy}
                            >
                                <span>#{homeId}</span>
                            </CopyToClipboard>
                            <span
                                className={classNames('home-id-copied-toast', {
                                    active: copiedActive
                                })}
                            >
                                {i18n.t('common:url-copied')}
                            </span>
                        </div>
                    </React.Fragment>
                )}
                {legalRegistration &&
                    legalRegistration.data?.map((el) => {
                        return (
                            el.value && (
                                <React.Fragment key={el.name}>
                                    <div className="d-flex">
                                        <h3>
                                            {getTextLegaleRegistrations(
                                                'home:legal-registration-label',
                                                legalRegistration.name,
                                                el.name
                                            )}
                                        </h3>
                                        <div className="legal-registration-tooltip-icon-container">
                                            <Tooltip
                                                title={getTextLegaleRegistrations(
                                                    'home:legal-registration-tooltip',
                                                    legalRegistration.name,
                                                    el.name
                                                )}
                                                placement="right"
                                            >
                                                <i className="icon icon-infomation-circle"></i>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div id={el.name}>
                                        <span>{el.value}</span>
                                    </div>
                                </React.Fragment>
                            )
                        );
                    })}
            </div>
        );
    }
}

const mapStateToProps = (state, { homeId }) => {
    const home = state.loadedHomes[homeId] || null;
    let homeDescription = home ? home.get('descriptions') : null;
    homeDescription = homeDescription ? _.indexBy(homeDescription, 'locale') : null;
    // remove title and locale from description
    homeDescription = _.mapObject(homeDescription, (translation) => {
        translation = _.omit(translation, 'title', 'locale');
        translation = _.omit(translation, (t) => !t);
        return translation;
    });
    // delete empty descriptions
    Object.keys(homeDescription).map((key) => _.isEmpty(homeDescription[key]) && delete homeDescription[key]);
    return {
        legalRegistration: home ? home.attributes.legal_registration_homes[0] : null,
        homeId: home ? home.id : null,
        descriptions: homeDescription,
        prefersReciprocal: home ? Boolean(home.get('prefers_reciprocal')) : null,
        user: home ? home.get('user') : null
    };
};

export default connect(mapStateToProps)(HomeDescription);
