import { useState, useEffect, useCallback } from 'react';
import ApiHome from '../../../api/Home';

export const LOADING_MESSAGE = 'Loading...';

export const DEFAULT_HOME = {
    id: 1,
    image_url: 'https://image.homeexchange.fr/images/website/migration/placeholders/home_gallery.svg',
    title: LOADING_MESSAGE,
    shared_part: 1,
    is_he_collection: false,
    verification: { label: 'not_verified' }
};

const useFetchHomes = () => {
    const [homes, setHomes] = useState([DEFAULT_HOME]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchHomes = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await ApiHome.getVerificationHomes();
            setHomes(response);
        } catch (error) {
            console.error('Error fetching homes:', error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchHomes();
    }, [fetchHomes]);

    return { homes, isLoading, fetchHomes };
};

export default useFetchHomes;
