import { useEffect } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { fetchAvailabilities } from '../../actions/availabilities';
import { fetchHomeRatings } from '../../actions/homeRatings';
import { fetchHome } from '../../actions/loadedHomes';
import { fetchUser } from '../../actions/loadedUsers';
import { fetchSavedSearches } from '../../actions/savedSearches';
import { fetchHomeFavorites } from '../../actions/homeFavorites';

function HomeLoader({
    userId,
    homeId,
    isLogged,
    fetchAvailabilities,
    fetchHomeRatings,
    fetchHome,
    fetchUser,
    fetchHomeFavorites,
    fetchSavedSearches
}) {
    useEffect(() => {
        fetchHome(homeId);
        fetchAvailabilities(homeId);
        fetchHomeRatings(homeId);
        fetchUser(userId);
        if (isLogged) {
            fetchHomeFavorites();
            fetchSavedSearches();
        }
    }, []);

    return false;
}

const mapDispatchToProps = (dispatch) => ({
    fetchHome: bindActionCreators(fetchHome, dispatch),
    fetchAvailabilities: bindActionCreators(fetchAvailabilities, dispatch),
    fetchHomeRatings: bindActionCreators(fetchHomeRatings, dispatch),
    fetchHomeFavorites: bindActionCreators(fetchHomeFavorites, dispatch),
    fetchSavedSearches: bindActionCreators(fetchSavedSearches, dispatch),
    fetchUser: bindActionCreators(fetchUser, dispatch)
});

export default connect(null, mapDispatchToProps)(HomeLoader);
