import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import i18n from '../../i18n';

import User from '../../models/User';

import Stars from '../rating/Stars';

export class ExchangesReviewsInfo extends React.PureComponent {
    static propTypes = {
        user: PropTypes.instanceOf(User)
    };

    render() {
        const { user } = this.props;
        if (!user) {
            return null;
        }
        return (
            <div className="exchanges-reviews-info">
                <div>
                    {user.get('global_rating') > 0 && <Stars stars={user.get('global_rating')} />}
                    {user.get('number_exchange') > 0 && (
                        <span className="nb-exchange">
                            {i18n.t('common:exchange_count', {
                                count: user.get('number_exchange')
                            })}
                        </span>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    user: state.loadedUsers[ownProps.userId]
});

export default connect(mapStateToProps)(ExchangesReviewsInfo);
