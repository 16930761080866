import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import i18n from '../../i18n';
import Utils from '../../utils/utils';
import Api from '../../api/Api';

function UserSponsor(props) {
    const { user } = props;
    const [hasGodfather, setHasGodfather] = useState(false);
    const [godsonCounter, setGodsonCounter] = useState(0);
    const [isAmbassador, setIsAmbassador] = useState(false);

    useEffect(() => {
        if (user) {
            Api.Ambassador.get(user?.get('id')).then((response) => {
                setIsAmbassador(response.is_ambassador);
            });
        }
    }, [user]);

    useEffect(() => {
        if (user) {
            Api.Sponsor.getGodfatherByUserId(user.get('id')).then((godfather) => {
                if (godfather) {
                    setHasGodfather(godfather.sponsor_info !== null);
                    setGodsonCounter(godfather.nb_sponsored_user);
                }
            });
        }
    }, [user]);

    if (!user || !hasGodfather || godsonCounter > 0 || isAmbassador) {
        return null;
    }

    return (
        <>
            <div role="separator" className="divider"></div>
            <div className="user-sponsor-container">
                <i className="icon-sponsor-hand-icon" />
                <p className="user-sponsor-text">
                    {i18n.t('sponsorship:homeview.user-sponsor.text', {
                        firstname: user.get('first_name')
                    })}
                    <a
                        className="show"
                        href={i18n.t('user:user.view.sponsored-faq')}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {i18n.t('common:learn_more')}
                    </a>
                </p>
            </div>
        </>
    );
}

UserSponsor.propTypes = {
    user: PropTypes.object
};

const mapStateToProps = (state) => {
    const id = Utils.getLastUrlPath();
    const home = state.loadedHomes[id];

    return { user: home ? home.get('user') : null };
};

export default connect(mapStateToProps)(UserSponsor);
