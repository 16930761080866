import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';
import { Modal, Callout, Text, Link, Container, Carousel, CarouselItem } from '@homeexchange/design';
import useFetchHomes from './useFetchHomes';
import HomeCard from './HomeCard';
import {
    trackVerificationModalView,
    trackVerificationButtonClick
} from '../../../utils/utils-ts/trackingUtils';

const VerificationModal = ({ show, closeModal }) => {
    const [needsRefresh, setNeedsRefresh] = useState(false);
    const { homes, isLoading, fetchHomes } = useFetchHomes();

    useEffect(() => {
        if (show && needsRefresh) {
            fetchHomes();
            setNeedsRefresh(false);
        }
    }, [show, needsRefresh, fetchHomes]);

    const handleVerification = (id) => {
        const verificationUrl = i18n.t('url:home-verification', { id });

        window.open(verificationUrl, '_blank');

        trackVerificationButtonClick();
        closeModal();
        setNeedsRefresh(true);
    };

    useEffect(() => {
        if (!show) return;
        trackVerificationModalView();
    }, [show]);

    return (
        <Modal
            title={i18n.t('collection:contact-blocker.verify-account.title')}
            isOpen={show}
            onClose={closeModal}
        >
            <div
                className="verification-info"
                dangerouslySetInnerHTML={{
                    __html: i18n.t('collection:contact-blocker.verify-account.text')
                }}
            ></div>

            <Container className="verification-callout-infos">
                <Callout status="info" hasIcon>
                    <Text>
                        {i18n.t('collection:contact-blocker.verif-account.callout.text')}
                        <Link
                            href="mailto:collection@homeexchange.com?subject=Question related to Verification"
                            target="_blank"
                        >
                            {i18n.t('collection:contact-blocker.verif-account.callout.link')}
                        </Link>
                    </Text>
                </Callout>
            </Container>

            <div className="verification-status">
                <span className="title">
                    {i18n.t('collection:contact-blocker.verify-account.status-title')}
                </span>
                <Carousel className="homes-list">
                    {homes.map((home) => (
                        <CarouselItem key={home.id}>
                            <HomeCard
                                key={home.id}
                                home={home}
                                isLoading={isLoading}
                                handleVerification={handleVerification}
                            />
                        </CarouselItem>
                    ))}
                </Carousel>
            </div>
        </Modal>
    );
};

VerificationModal.propTypes = {
    show: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired
};

export default VerificationModal;
