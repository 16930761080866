import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';

import HomeModel from '../../../models/Home';

import SuccessIcon from '../animations/Success';

export default class Success extends React.PureComponent {
    static propTypes = {
        home: PropTypes.instanceOf(HomeModel).isRequired,
        nbConversations: PropTypes.number,
        nbConversationsObjective: PropTypes.number,
        nbGPObjective: PropTypes.number,
        search: PropTypes.shape({
            place: PropTypes.string
        })
    };

    render() {
        const { home, nbConversations, nbConversationsObjective, nbGPObjective, search } = this.props;
        const spanStyle = { color: 'red' };

        return (
            <section className="popup-contact popup-contact-success">
                <div className="modal-body text-center vertical-center">
                    <button
                        type="button"
                        className="close close-popup"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div className="popup-icon">
                        <SuccessIcon />
                    </div>
                    <div>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: i18n.t('exchange:contact.send_success_text', {
                                    first_name: home.get('user').get('first_name'),
                                    response_rate: home.get('user').get('response_rate')
                                })
                            }}
                        />
                        {(() => {
                            if (home.get('user').get('response_rate') <= 50) {
                                return (
                                    <div
                                        className="response-rate-info"
                                        style={spanStyle}
                                        dangerouslySetInnerHTML={{
                                            __html: i18n.t(
                                                'exchange:contact.success_text_warning_about_response_rate',
                                                {
                                                    first_name: home.get('user').get('first_name'),
                                                    response_rate: home.get('user').get('response_rate')
                                                }
                                            )
                                        }}
                                    />
                                );
                            } else {
                                return (
                                    <div
                                        className="response-rate-info"
                                        dangerouslySetInnerHTML={{
                                            __html: i18n.t(
                                                'exchange:contact.send_success_text_add_response_rate',
                                                {
                                                    first_name: home.get('user').get('first_name'),
                                                    response_rate: home.get('user').get('response_rate')
                                                }
                                            )
                                        }}
                                    />
                                );
                            }
                        })()}
                    </div>
                    {(() => {
                        if (!_.isNull(nbConversations) && nbConversations <= nbConversationsObjective) {
                            return (
                                <div className="nb-conversations">
                                    {(() => {
                                        if (nbConversations < nbConversationsObjective) {
                                            return i18n.t('exchange:contact.conversations_until_objective', {
                                                count: nbConversationsObjective - nbConversations,
                                                gp_amount: nbGPObjective
                                            });
                                        } else if (nbConversations === nbConversationsObjective) {
                                            return i18n.t(
                                                'exchange:contact.conversations_objective_reached',
                                                {
                                                    nb_conv: nbConversationsObjective,
                                                    gp_amount: nbGPObjective
                                                }
                                            );
                                        }
                                    })()}
                                </div>
                            );
                        }
                    })()}
                    <hr />
                    <div className="row">
                        <div className="col-xs-12">
                            <p className="footer-message">
                                {i18n.t('exchange:contact.invite_to_contact_more_members2')}
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <a
                                href={i18n.t('search:search_url', {
                                    slug: search?.place ? encodeURIComponent(search.place) : 'everywhere'
                                })}
                                className="btn btn-block btn-primary"
                            >
                                {i18n.t('exchange:contact.contact_more_members')}
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
