import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loading from '../common/Loading';
import Home from '../../models/Home';
import i18n from '../../i18n';

const HomeDetail = (props) => {
    const path = window.location.pathname;
    const lastSlashIndex = path.lastIndexOf('/');
    const homeId = path.substring(lastSlashIndex + 1);
    const details = props.details[homeId];
    const arrayDetails = details && [
        ...(details.get('shared_part') === Home.SHARED_PART_PRIVATE_ROOM
            ? [
                  {
                      name: 'private-room',
                      icon: 'icon-private-room',
                      text: i18n.t('home:home.view.private_room')
                  }
              ]
            : []),
        {
            name: 'type',
            icon: `icon-${details.get('type') === Home.TYPE_APARTMENT ? 'flat' : 'house'}`,
            text:
                details.get('type') === Home.TYPE_APARTMENT
                    ? i18n.t('home:type-flat')
                    : i18n.t('home:type-home')
        },
        {
            name: 'residence_type',
            icon:
                details.get('detail').residence_type === Home.RESIDENCE_PRIMARY_TEMP ||
                details.get('detail').residence_type === Home.RESIDENCE_PRIMARY
                    ? 'icon-primary-home'
                    : 'icon-secondary-home',
            text:
                details.get('detail').residence_type === Home.RESIDENCE_PRIMARY_TEMP ||
                details.get('detail').residence_type === Home.RESIDENCE_PRIMARY
                    ? i18n.t('search:filter.main_home')
                    : i18n.t('search:filter.secondary_home')
        },
        {
            name: 'capacity',
            icon: 'icon-beds',
            text:
                details.get('capacity') > 1
                    ? i18n.t('home:capacity_plural', { count: details.get('capacity') })
                    : i18n.t('home:capacity', { count: details.get('capacity') })
        },
        {
            name: 'bedroom_nb',
            icon: 'icon-bedroom',
            text:
                details.get('detail').bedroom_nb === 0
                    ? i18n.t('home:studio')
                    : details.get('detail').bedroom_nb > 1
                    ? i18n.t('home:bedroom_nb_plural', {
                          count: details.get('detail').bedroom_nb
                      })
                    : i18n.t('home:bedroom_nb', {
                          count: details.get('detail').bedroom_nb
                      })
        },
        {
            name: 'bathroom_nb',
            icon: 'icon-bathroom',
            text:
                details.get('detail').bathroom_nb > 1
                    ? i18n.t('home:bathroom_nb_plural', {
                          count: details.get('detail').bathroom_nb
                      })
                    : i18n.t('home:bathroom_nb', {
                          count: details.get('detail').bathroom_nb
                      })
        },
        {
            name: 'localized_size',
            icon: 'icon-size',
            text: `${details.get('detail').localized_size} ${
                details.get('detail').size_unit === 'sqft' ? 'ft²' : 'm²'
            }`
        }
    ];

    return !details ? (
        <Loading />
    ) : (
        arrayDetails.map((detail) => (
            <div
                key={detail.name}
                className={`home-spec ${detail.name === 'capacity' ? 'capitalize-text' : ''}`}
                {...(detail.name === 'localized_size' && {
                    itemProp: 'floorSize',
                    itemScope: true,
                    itemType: 'http://schema.org/QuantitativeValue'
                })}
                {...(detail.name === 'bedroom_nb' && {
                    itemProp: 'numberOfRooms',
                    itemScope: true,
                    itemType: 'http://schema.org/QuantitativeValue'
                })}
            >
                <i className={`icon ${detail.icon}`} />
                {detail.name === 'bedroom_nb' && (
                    <meta itemProp="value" content={details.get('detail').bedroom_nb} />
                )}
                {detail.name === 'localized_size' && (
                    <meta
                        itemProp="unitCode"
                        content={details.get('detail').size_unit === 'm2' ? 'MTK' : 'FTK'}
                    />
                )}
                {detail.text}
            </div>
        ))
    );
};

HomeDetail.propTypes = {
    details: PropTypes.object
};

const mapStateToProps = (state) => ({
    details: state.loadedHomes
});

export default connect(mapStateToProps)(HomeDetail);
