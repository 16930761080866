import i18n from '../../../i18n';
import CalendarUtils from '../../../utils/CalendarUtils';
import InputRangeCalendar from '../../calendar/InputRangeCalendar';
import Availability from '../../../models/Availability';

export default class ContactCalendar extends InputRangeCalendar {
    /**
     * Handle react validation
     */
    checkValidity() {
        const selected = this.calendar.getSelectedRaw();

        if (Array.isArray(selected) && selected.length === 2) {
            const selectedRange = moment.range(selected[0], selected[1]);
            const inAvailablePeriod = CalendarUtils.inAvailablePeriod(
                selectedRange,
                this.props.availabilities
            );

            this.input.setCustomValidity(''); // valid
            if (!inAvailablePeriod) {
                // Set a warning
                $(this.input).data('warning', i18n.t('common:validity_datesUnavailable'));
            } else {
                // Remove warning
                $(this.input).data('warning', null);
            }
        } else if (this.props.required) {
            // The user has to select dates
            this.input.setCustomValidity(i18n.t('error:validity_datesMandatory'));
        } else {
            this.input.setCustomValidity(''); // valid
            $(this.input).data('warning', null);
        }

        // Trigger change event to be able to handle validity check
        $(this.input).trigger('change');
    }

    /**
     * If the user doesn't want to be contact on unavailable period, change blackout options
     */
    getBlackout() {
        if (!this.props.contactOnUnavailablePeriods) {
            const availableDates = [];

            this.props.availabilities.forEach((availability) => {
                if (availability.get('type') !== Availability.BOOKED.type) {
                    const range = moment.range(availability.get('start_on'), availability.get('end_on'));
                    for (const day of range.by('days')) {
                        availableDates.push(day.format(this.format));
                    }
                }
            });

            // Return a function that returns true only for the available dates
            return (date) => {
                if (availableDates.indexOf(date.format(this.format)) >= 0) {
                    return false;
                }
                // Date is not selectable
                return true;
            };
        } else {
            return super.getBlackout();
        }
    }
}
