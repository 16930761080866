import React from 'react';
import PropTypes from 'prop-types';
import Feature from '../../models/Feature';
import i18n from '../../i18n';

function HomeAllergiesInformation({ features }) {
    return (
        features.animal > 0 && (
            <div className="home-allergies-information">
                <h2 className="home-allergies-information-title">
                    {i18n.t('home:home-creation.allergies.title')}
                </h2>
                <div className="home-allergies-information-text-container">
                    <p className="home-allergies-information-text">
                        {i18n.t('home:feature-allergy.home-view.text')}
                    </p>
                    <div className="home-allergies-information-icons-container">
                        {Feature.FEATURES.filter((item) => item.category === 10).map((feature) => {
                            const category = Feature.AMENITIES_LABEL[feature.category];
                            return (
                                Boolean(features[category] & feature.value) && (
                                    <div className="information-icon">
                                        <i className={`icon icon-${feature.icon}`} />
                                        <span>{i18n.t(`home:${feature.label}`)}</span>
                                    </div>
                                )
                            );
                        })}
                    </div>
                </div>
            </div>
        )
    );
}

HomeAllergiesInformation.propTypes = {
    features: PropTypes.object
};

export default HomeAllergiesInformation;
