import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Home from '../../models/Home';
import MapComponent from '../common/map/mapComponent';
import Loading from '../common/Loading';

export const HomeMap = ({ home }) => {
    const [loading, setLoading] = useState(!home);

    useEffect(() => {
        if (home) {
            setLoading(false);
        }
    }, [home]);

    return loading ? (
        <Loading />
    ) : (
        <MapComponent
            latitude={home.get('location')?.latitude}
            longitude={home.get('location')?.longitude}
            showControls
            centeredCircle
        />
    );
};

HomeMap.propTypes = {
    home: PropTypes.instanceOf(Home)
};

const mapStateToProps = (state, ownProps) => ({
    home: state.loadedHomes[ownProps.homeId],
    user: state.user
});

export default connect(mapStateToProps)(HomeMap);
