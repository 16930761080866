import React from 'react';
import { Flex, Image, Modal, Text } from '@homeexchange/design';
import Utils from '../../../../utils/utils';
export var HomeBlocker = function (_a) {
    var title = _a.title, message = _a.message, confirmText = _a.confirmText, isOpen = _a.isOpen, setIsOpen = _a.setIsOpen, confirmAction = _a.confirmAction;
    return (React.createElement(Modal, { title: title, onConfirmAction: confirmAction, confirmActionText: confirmText, isOpen: isOpen, onClose: function () { return setIsOpen(false); } },
        React.createElement(Flex, { direction: "column", alignItems: "center" },
            React.createElement(Image, { alt: "", src: "".concat(Utils.getCloudfrontDomain(), "/images/website/home/living-room-regular.png") }),
            React.createElement(Text, { className: "text-center", dangerouslySetInnerHTML: { __html: message } }))));
};
