import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import HomeModel from '../../models/Home';
import i18n from '../../i18n';
import UserModel from '../../models/User';
import Loading from '../common/Loading';
import Rating from './Rating';
import StarRatingCompact from './StarRatingCompact';
import RatingRGPDRequirement from '../rating/RatingRGPDRequirement';
import BlurredRatingHeadband from '../rating/BlurredRatingHeadband';
import Utils from '../../utils/utils';

export class HomeRating extends React.PureComponent {
    static propTypes = {
        user: PropTypes.instanceOf(UserModel),
        loggedUser: PropTypes.instanceOf(UserModel),
        homeRatings: PropTypes.array,
        ratingAvg: PropTypes.number,
        home: PropTypes.instanceOf(HomeModel)
    };

    render() {
        if (!this.props.user || !this.props.homeRatings || !this.props.loggedUser) {
            return <Loading />;
        }
        const { home } = this.props;
        let homeImageUrl = `${Utils.getCloudfrontDomain()}/images/website/logo/he-logo.svg`;
        if (home && typeof home.get('images') !== 'undefined') {
            homeImageUrl =
                home.get('images').length > 0
                    ? home.get('images')[0].cdn_link
                    : `${Utils.getCloudfrontDomain()}/images/website/logo/he-logo.svg`;
        }
        let homeDescription = '';
        if (home && typeof home.get('descriptions') !== 'undefined') {
            homeDescription =
                home.get('descriptions').find((d) => !_.isEmpty(d.title) && d.locale === i18n.language) || '';
        }
        return (
            <React.Fragment>
                <div className="ratings-listing-header-container">
                    <h2 className="ratings-listing-header">
                        {i18n.t('home:reviews.home', {
                            count: this.props.homeRatings.length
                        })}
                        {!_.isNull(this.props.ratingAvg) && (
                            <span
                                className="home-rating-title"
                                itemProp="aggregateRating"
                                itemScope
                                itemType="http://schema.org/AggregateRating"
                            >
                                <span
                                    itemProp="itemReviewed"
                                    itemScope
                                    itemType="https://schema.org/LocalBusiness"
                                >
                                    <meta itemProp="image" content={homeImageUrl.cdn_link} />
                                    <meta itemProp="name" content={homeDescription.title} />
                                </span>
                                <meta itemProp="reviewCount" content={this.props.homeRatings.length || 5} />
                                <meta itemProp="ratingValue" content={this.props.ratingAvg || 5} />
                                <StarRatingCompact nbStars={this.props.ratingAvg} />
                            </span>
                        )}
                    </h2>
                    <a
                        href={`${i18n.t('user:user.view.url', {
                            id: this.props.user.get('id')
                        })}#ratings`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {i18n.t('home:ratings.listing.see-all-reviews', {
                            nbReviews: this.props.homeRatings.length
                        })}
                    </a>
                </div>
                {!this.props.loggedUser.get('is_subscribed') ? (
                    <BlurredRatingHeadband />
                ) : (
                    <RatingRGPDRequirement />
                )}

                <Rating
                    mode="home"
                    user={this.props.user}
                    loggedUser={this.props.loggedUser}
                    ratings={this.props.homeRatings}
                    ratingsData={{
                        avg: this.props.ratingAvg,
                        exchangeNb: this.props.homeRatings.length
                    }}
                />
            </React.Fragment>
        );
    }
}

function getHomeRatingsFromState(state, ownProps) {
    if (
        state.homeRatings &&
        ownProps.homeId &&
        state.homeRatings[ownProps.homeId] &&
        state.homeRatings[ownProps.homeId].ratings
    ) {
        return state.homeRatings[ownProps.homeId].ratings;
    }
    return null;
}

function getRatingAvgFromState(state, ownProps) {
    if (
        state.homeRatings &&
        ownProps.homeId &&
        state.homeRatings[ownProps.homeId] &&
        state.homeRatings[ownProps.homeId].average
    ) {
        return state.homeRatings[ownProps.homeId].average;
    }
    return null;
}

function getUserFromState(state, ownProps) {
    if (
        state.loadedHomes &&
        ownProps.homeId &&
        state.loadedHomes[ownProps.homeId] &&
        state.loadedHomes[ownProps.homeId].has('user')
    ) {
        return state.loadedHomes[ownProps.homeId].get('user');
    } else if (state.homes && ownProps.homeId) {
        const currentHome = state.homes.find((home) => home.id === ownProps.homeId);
        if (currentHome && currentHome.has('user')) {
            return currentHome.get('user');
        }
    }
    return null;
}

function getHomeFromState(state, ownProps) {
    if (state.loadedHomes && ownProps.homeId && state.loadedHomes[ownProps.homeId]) {
        return state.loadedHomes[ownProps.homeId];
    }
    return null;
}

const mapStateToProps = (state, ownProps) => ({
    homeRatings: getHomeRatingsFromState(state, ownProps),
    ratingAvg: getRatingAvgFromState(state, ownProps),
    loggedUser: state.user,
    user: getUserFromState(state, ownProps),
    home: getHomeFromState(state, ownProps)
});

export default connect(mapStateToProps)(HomeRating);
