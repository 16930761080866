import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';

import Home from '../../../models/Home';
import User from '../../../models/User';
import Availability from '../../../models/Availability';
import Tooltip from '../../widget/Tooltip';

import Storage from '../../../utils/Storage';

export default class MessageField extends React.PureComponent {
    static propTypes = {
        user: PropTypes.instanceOf(User).isRequired,
        home: PropTypes.instanceOf(Home).isRequired,
        exchangeType: PropTypes.number.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            messagePrewritten: false,
            currentMessage: this.loadLastMessage(props.user, props.home)
        };
        this.messageChange = this.messageChange.bind(this);
        this.getPreWrittenMessage = this.getPreWrittenMessage.bind(this);
        this.switchMessage = this.switchMessage.bind(this);
        this.getMessage = this.getMessage.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.exchangeType !== this.props.exchangeType && this.state.messagePrewritten) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                currentMessage: this.getPreWrittenMessage()
            });
        }
    }

    // load user's last sent message
    loadLastMessage(user, home) {
        let message = Storage.get(`${user.id}_last_contact_message`);
        if (!message) {
            return '';
        }
        const shouldBeReplaced = {
            userName: home.get('user').get('first_name'),
            userDestination: home.firstAdmin()
        };
        if (typeof message === 'string') {
            message = message.replace(new RegExp('{{userName}}|{{userDestination}}', 'g'), (matched) => {
                matched = matched.slice(2, matched.length - 2);
                return shouldBeReplaced[matched];
            });
        }
        return message;
    }

    messageChange(event) {
        const message = event.target.value ? event.target.value : '';
        this.setState({
            currentMessage: message
        });
        this.saveMessage(message);
    }

    saveMessage(message) {
        const { user, home } = this.props;
        // shouldn't store the last character
        if (!message || message === '') {
            Storage.remove(`${user.id}_last_contact_message`);
            return;
        }
        // change name to a variable only if receiver's name is not the sender's name;
        // change also destination to a variable
        if (
            this.props.user.get('first_name') != home.get('user').get('first_name') &&
            typeof message === 'string'
        ) {
            message = message.replace(new RegExp(home.get('user').get('first_name'), 'g'), '{{userName}}');
            message = message.replace(new RegExp(home.firstAdmin(), 'g'), '{{userDestination}}');
        }

        Storage.set(`${this.props.user.id}_last_contact_message`, message);
    }

    getMessage() {
        return this.state.currentMessage;
    }

    getPreWrittenMessage() {
        const hostName = this.props.home.get('user').get('first_name');
        const destination = this.props.home.firstAdmin();
        const userLocation = this.props.user.getPrimaryHome().firstAdmin();

        switch (this.props.exchangeType) {
            case Availability.AVAILABLE.value:
                return i18n.t('exchange:contact.prewritten_message_any_type', {
                    host_name: hostName,
                    destination,
                    user_location: userLocation
                });
            case Availability.NON_RECIPROCAL.value:
                return i18n.t('exchange:contact.prewritten_message_non_reciprocal', {
                    host_name: hostName,
                    destination
                });
            case Availability.RECIPROCAL.value:
                return i18n.t('exchange:contact.prewritten_message_reciprocal', {
                    host_name: hostName,
                    destination,
                    user_location: userLocation
                });
            default:
                return i18n.t('exchange:contact.prewritten_message_any_type', {
                    host_name: hostName,
                    destination,
                    user_location: userLocation
                });
        }
    }

    switchMessage() {
        const { messagePrewritten } = this.state;
        let message = '';
        if (!messagePrewritten) {
            message = this.getPreWrittenMessage();
        }
        this.setState(
            {
                messagePrewritten: !messagePrewritten,
                currentMessage: message
            },
            () => {
                $('#content').trigger('change');
            }
        );
    }

    render() {
        const hostName = this.props.home.get('user').get('first_name');
        const destination = this.props.home.firstAdmin();
        return (
            <div className="popup-field-block">
                <div className="row">
                    <div className="col-md-12">
                        <p>
                            {i18n.t('exchange:contact.personalized_message_label', {
                                first_name: this.props.home.get('user').get('first_name')
                            })}
                            <Tooltip
                                placement="bottom"
                                title={i18n.t('exchange:contact.personalized_message_help_text', {
                                    first_name: this.props.home.get('user').get('first_name'),
                                    languages: this.props.home.get('user').languagesAsLabels().join(', ')
                                })}
                            >
                                <i className="icon-circle-help" />
                            </Tooltip>
                        </p>
                    </div>
                </div>
                <div className="row personalized_message_efficiency_alert">
                    <div className="col-md-12">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: i18n.t('exchange:contact.personalized_message_efficiency_alert', {
                                    efficiency: '<span class="efficiency">25%</span>'
                                })
                            }}
                        />
                    </div>
                    <button type="button" className="message-switch-button" onClick={this.switchMessage}>
                        {this.state.messagePrewritten
                            ? i18n.t('exchange:contact.switch_message_button_personalized')
                            : i18n.t('exchange:contact.switch_message_button_pre_written')}
                    </button>
                </div>
                <div className="form-group form-group-content">
                    <textarea
                        className="form-control"
                        name="content"
                        id="content"
                        rows="8"
                        value={this.getMessage()}
                        onChange={this.messageChange}
                        onBlur={this.messageChange}
                        placeholder={i18n.t('exchange:contact.personalized_message_placeholder', {
                            first_name: hostName,
                            destination
                        })}
                        required
                    />
                </div>
            </div>
        );
    }
}
