import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import AggregateRating from './AggregateRating';

export class HomeAggregateRating extends React.PureComponent {
    static propTypes = {
        ratingAvg: PropTypes.number,
        exchangeNumber: PropTypes.number
    };

    render() {
        if (!_.isNull(this.props.exchangeNumber)) {
            return (
                <AggregateRating average={this.props.ratingAvg} exchangeNumber={this.props.exchangeNumber} />
            );
        }

        return null;
    }
}

function getExchangeNumberFromState(state, ownProps) {
    if (
        state.homeRatings &&
        ownProps.homeId &&
        state.homeRatings[ownProps.homeId] &&
        state.homeRatings[ownProps.homeId].ratings
    ) {
        return state.homeRatings[ownProps.homeId].ratings.length;
    }
    return null;
}

function getRatingAvgFromState(state, ownProps) {
    if (
        state.homeRatings &&
        ownProps.homeId &&
        state.homeRatings[ownProps.homeId] &&
        state.homeRatings[ownProps.homeId].average
    ) {
        return state.homeRatings[ownProps.homeId].average;
    }
    return null;
}

const mapStateToProps = (state, ownProps) => ({
    exchangeNumber: getExchangeNumberFromState(state, ownProps),
    ratingAvg: getRatingAvgFromState(state, ownProps)
});

export default connect(mapStateToProps)(HomeAggregateRating);
