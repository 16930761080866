import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Home from '../../models/Home';
import User from '../../models/User';
import i18n from '../../i18n';
import Utils from '../../../src/utils/utils';
import Loading from '../../../src/components/common/Loading';

const HomeSectionCollection = (props) =>
    !props.home ? (
        <Loading />
    ) : !props.user?.isUserEligibleCollection() && props.home?.get('is_collection') ? (
        <div className="container-collection-home-view container-eligible-collection">
            <img
                alt="container-collection-home-view"
                className="img-he-collection-block"
                src={`${Utils.getCloudfrontDomain()}/images/website/home/HE-LUXE_LOGO_BLANC&BEIGE.svg`}
            />
            <div className="container-text-he-collection">
                <p
                    dangerouslySetInnerHTML={{
                        __html: i18n.t('home:exceptional_stylish')
                    }}
                />
                <p
                    dangerouslySetInnerHTML={{
                        __html: i18n.t('home:this_home_part_collection')
                    }}
                />
                <a
                    className="he-collection-p p-eligible-collection"
                    href={`${i18n.t('common:gtg.url')}collection`}
                >
                    {i18n.t('common:learn_more')}
                </a>
            </div>
        </div>
    ) : null;

HomeSectionCollection.propTypes = {
    home: PropTypes.instanceOf(Home),
    user: PropTypes.instanceOf(User)
};

const mapStateToProps = (state, ownProps) => ({
    home: state.loadedHomes[ownProps.homeId],
    user: state.user
});

export default connect(mapStateToProps)(HomeSectionCollection);
