import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import User from '../../models/User';
import i18n from '../../i18n';

export const TravelingGroupInfo = (props) => {
    const { composition, user, isLogged, currentUser } = props;
    const renderContent = () => {
        const userName = user.get('first_name');
        const nbTravelers = parseInt(composition.total, 10) - 1;
        const nbAdults = composition.adult ? parseInt(composition.adult, 10) - 1 : 0; // we suppose that default traveler is always an adult
        const nbTeenagers = composition.adolescent ? parseInt(composition.adolescent, 10) : 0;
        const nbChildren = composition.child ? parseInt(composition.child, 10) : 0;
        const nbBabies = composition.baby ? parseInt(composition.baby, 10) : 0;

        let travelerTextInfo = '';

        if (nbAdults > 0) {
            const rightTranslation =
                nbAdults === 1
                    ? i18n.t('user:traveler.type_adult').toLowerCase()
                    : i18n.t('user:traveler.type_adult_plural').toLowerCase();
            travelerTextInfo = `${nbAdults} ${rightTranslation}`;
        }

        if (nbTeenagers > 0) {
            const rightTranslation =
                nbTeenagers === 1
                    ? i18n.t('user:traveler.type_teenager').toLowerCase()
                    : i18n.t('user:traveler.type_teenager_plural').toLowerCase();
            travelerTextInfo +=
                travelerTextInfo !== ''
                    ? `, ${nbTeenagers} ${rightTranslation}`
                    : `${nbTeenagers} ${rightTranslation}`;
        }

        if (nbChildren > 0) {
            const rightTranslation =
                nbChildren === 1
                    ? i18n.t('user:traveler.type_child').toLowerCase()
                    : `${i18n.t('user:traveler.type_child_plural').toLowerCase()}`;
            travelerTextInfo +=
                travelerTextInfo !== ''
                    ? `, ${nbChildren} ${rightTranslation}`
                    : `${nbChildren} ${rightTranslation}`;
        }

        if (nbBabies > 0) {
            const rightTranslation =
                nbBabies === 1
                    ? i18n.t('user:traveler.type_baby').toLowerCase()
                    : `${i18n.t('user:traveler.type_baby_plural').toLowerCase()}`;
            travelerTextInfo +=
                travelerTextInfo !== ''
                    ? `, ${nbBabies} ${rightTranslation}`
                    : `${nbBabies} ${rightTranslation}`;
        }

        const baseUrl = `${i18n.t('user:user.view.url', { id: user.get('id') })}`;
        const link =
            nbTravelers === 1
                ? `<a href="${baseUrl}#user-view-traveling-group">${nbTravelers} ${i18n.t(
                      'user:traveling_group_info_complement'
                  )}</a>`
                : `<a href="${baseUrl}#user-view-traveling-group">${nbTravelers} ${i18n.t(
                      'user:traveling_group_info_complement_plural'
                  )}</a>`;

        return `${i18n.t('user:homeview.contact.traveling_group_info', {
            'first-name': userName,
            'nb-travelers': link
        })} (${travelerTextInfo})`;
    };

    if (user && parseInt(composition.total, 10) > 1) {
        return (
            <React.Fragment>
                <div role="separator" className="divider" />
                <div className="traveling-group-info-content">
                    <i
                        className={
                            isLogged && currentUser && currentUser.get('is_he_collection')
                                ? 'icon-travelers'
                                : 'icon-my-family'
                        }
                    />
                    <span dangerouslySetInnerHTML={{ __html: renderContent() }} />
                </div>
            </React.Fragment>
        );
    }
    return false;
};

TravelingGroupInfo.propTypes = {
    user: PropTypes.instanceOf(User),
    currentUser: PropTypes.instanceOf(User),
    isLogged: PropTypes.bool,
    composition: PropTypes.object
};

const mapStateToProps = (state, ownProps) => ({
    user: state.loadedUsers[ownProps.userId],
    currentUser: state.user,
    isLogged: Boolean(state.auth.accessToken)
});

export default connect(mapStateToProps)(TravelingGroupInfo);
