import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Icons from '../../utils/Icons';
import i18n from '../../i18n';
import Utils from '../../utils/utils';
import Api from '../../api/Api';

function UserSponsorBadge(props) {
    const { user } = props;
    const [godsonCounter, setGodsonCounter] = useState(0);

    useEffect(() => {
        if (user) {
            Api.Sponsor.getGodfatherByUserId(user.get('id')).then((godfather) => {
                if (godfather) {
                    setGodsonCounter(godfather.nb_sponsored_user);
                }
            });
        }
    }, [user]);

    if (!user || godsonCounter === 0) {
        return null;
    }

    const firstname = user.get('first_name');

    return (
        <div className="home-card-push user-sponsor-badge">
            <div className="user-sponsor-badge-picture">
                <span className="icon-badge_referral">{Icons.transform(82)}</span>
                <span className="user-sponsor-badge-label">
                    {i18n.t('sponsorship:homeview.user-sponsor.badge.label', {
                        firstname
                    })}
                </span>
            </div>

            <div className="user-sponsor-badge-about">
                <h4 className="user-sponsor-badge-about-title">
                    {i18n.t('sponsorship:homeview.user-sponsor.badge.title', {
                        firstname,
                        value: godsonCounter,
                        singular_or_plural_word: Utils.getPersonOrPeople(godsonCounter)
                    })}
                </h4>
                <p>
                    {i18n.t('sponsorship:homeview.user-sponsor.badge.text', {
                        firstname,
                        value: godsonCounter,
                        singular_or_plural_word: Utils.getPersonOrPeople(godsonCounter)
                    })}
                    <a
                        className="show"
                        href={i18n.t('user:user.view.sponsor-faq')}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {i18n.t('common:learn_more')}
                    </a>
                </p>
            </div>
        </div>
    );
}

UserSponsorBadge.propTypes = {
    user: PropTypes.object
};

const mapStateToProps = (state) => {
    const id = Utils.getLastUrlPath();
    const home = state.loadedHomes[id];

    return { user: home ? home.get('user') : null };
};

export default connect(mapStateToProps)(UserSponsorBadge);
