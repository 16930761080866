import PropTypes from 'prop-types';
import React from 'react';
import i18n from '../../i18n';
import Stars from './Stars';

export default class AggregateRating extends React.PureComponent {
    static propTypes = {
        average: PropTypes.number,
        exchangeNumber: PropTypes.number.isRequired
    };

    render() {
        return (
            <div
                className="aggregate-rating"
                itemProp="aggregateRating"
                itemScope
                itemType="http://schema.org/AggregateRating"
            >
                <meta itemProp="reviewCount" value={this.props.exchangeNumber} />
                <span className="nb-exchange">
                    {i18n.t('common:exchange_count', {
                        count: this.props.exchangeNumber
                    })}
                </span>
                {(() => {
                    if (!_.isNull(this.props.average)) {
                        return (
                            <span>
                                <meta itemProp="ratingValue" value={this.props.average} />
                                <Stars stars={this.props.average} />
                            </span>
                        );
                    }
                })()}
            </div>
        );
    }
}
