import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';
import { DEFAULT_HOME, LOADING_MESSAGE } from './useFetchHomes';

const VERIFIED = 'verified';
const NOT_VERIFIED = 'not_verified';
const HomeCard = ({ home, isLoading, handleVerification }) => {
    const handleImageError = (event) => {
        event.target.src = DEFAULT_HOME.image_url;
    };

    const getButtonMessage = () => {
        if (isLoading) return { message: LOADING_MESSAGE, icon: '' };
        return { message: i18n.t('collection:contact-blocker.verify-account.button'), icon: '' };
    };

    const getTypeMessage = (sharedPart) => {
        if (isLoading) return LOADING_MESSAGE;
        return sharedPart === 2
            ? i18n.t('collection:contact-blocker.verify-account.private-room')
            : i18n.t('collection:contact-blocker.verify-account.whole-unit');
    };

    return (
        <div className="home-card">
            <div className="image-wrapper">
                <img src={home.image_url} alt={home.title} onError={handleImageError} />
                {home.is_he_collection && (
                    <div className="label-collection">{i18n.t('home:label_collection')}</div>
                )}
            </div>
            <div className="home-details">
                <span className="type">{getTypeMessage(home.shared_part)}</span>
                <span className="name">{home.title}</span>
                <span className="proof">
                    <span
                        className={`badge-verified ${
                            home.verification.label !== VERIFIED ? 'not-verified' : ''
                        }`}
                    ></span>
                    {isLoading
                        ? LOADING_MESSAGE
                        : i18n.t('collection:contact-blocker.verify-account.home-status')}
                </span>
                {home.verification.label !== VERIFIED && (
                    <button
                        onClick={() => handleVerification(home.id)}
                        disabled={home.verification.label !== NOT_VERIFIED || isLoading}
                        className="verify-btn"
                    >
                        {getButtonMessage(home.verification.label).message}
                    </button>
                )}
            </div>
        </div>
    );
};

HomeCard.propTypes = {
    home: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    handleVerification: PropTypes.func.isRequired
};

export default HomeCard;
