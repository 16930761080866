import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';

import HomeModel from '../../../models/Home';

export default class Processing extends React.PureComponent {
    static propTypes = {
        home: PropTypes.instanceOf(HomeModel)
    };

    render() {
        return (
            <section className="popup-contact">
                <header className="modal-header">
                    <h1 className="modal-title">
                        {i18n.t('exchange:contact.title', {
                            first_name: this.props.home.get('user').get('first_name')
                        })}
                    </h1>
                </header>
                <div className="modal-body text-center vertical-center">
                    <img className="gtg-processing-icon" src="/img/gtg.gif" alt="Processing…" />
                </div>
            </section>
        );
    }
}
